import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import clsx from "clsx"
import { formatPrice } from "../utils/format-price"
import {
  productCardStyle,
  productHeadingStyle,
  productImageStyle,
  productDetailsStyle,
  productVendorStyle,
  productPrice,
} from "./product-card.module.css"

export function ProductCard({ isSolo ,product, eager , fullRow}) {
  console.log(product)


  const {
    title,
    priceRangeV2,
    slug,
    images: [firstImage],
    storefrontImages,
    tags,

  } = product
  let tag = tags;

  let ogTag;
  for (var i = 0; i < tags.length; i++) {

    if (tags[i].split("-").includes("og")) {
      ogTag = tags[i].split("-")[1];
    
    } 
  }
  

  console.log(ogTag)
  // remove cents from price

  const isFullRow= fullRow;
  const price =  `$` + ~~priceRangeV2.minVariantPrice.amount;

  const defaultImageHeight = 400
  const defaultImageWidth = 400
  let storefrontImageData = {}
  if (storefrontImages) {
    const storefrontImage = storefrontImages.edges[0].node
    try {
      storefrontImageData = getShopifyImage({
        image: storefrontImage,
        layout: "fixed",
        width: defaultImageWidth,
        height: defaultImageHeight,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const hasImage = firstImage || Object.getOwnPropertyNames(storefrontImageData || {}).length

  return (
    <div className={clsx("flex flex-col ", isSolo && " block mx-auto self-center", isFullRow && "col-span-12 mx-auto")}>
    
    <Link
      to={slug}
      aria-label={`View ${title} product page`}
    >
      {hasImage
        ? (
          <div className={clsx(productImageStyle, "flex flex-col z-0 max-h-[60vh]", isSolo && "h-[53vh]")} data-name="product-image-box">
            <GatsbyImage
              objectFit="contain"
              className="h-full object-contain hover:opacity-80 transform transition duration-500 ease-in-out " 
              alt={firstImage?.altText ?? title}
              image={firstImage?.gatsbyImageData ?? storefrontImageData}
              loading={eager ? "eager" : "lazy"}
            />
          </div>
        ) : (
          <div style={{ height: defaultImageHeight, width: defaultImageWidth }} />
        )
      }
      <div className="text-center z-50 font-bold pb-[40px]">
        <h2 as="h2" className="text-lg capitalized">
          {title}
        </h2>
        {ogTag && 
            <span className="text-gray-400 line-through">${ogTag} </span>  
            
            }

            <h2 className={ogTag && "text-red-500"}>
            {price} 
            </h2>
      </div>
    </Link></div>
  )
}

export const query = graphql`
  fragment ProductCard on ShopifyProduct {
    id
    title
 
    slug: gatsbyPath(
      filePath: "/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}"
    )
    images {
      id
      altText
      gatsbyImageData
    }
    tags
    priceRangeV2 {
      minVariantPrice {
        amount
        currencyCode
      }
    }

  }
`
