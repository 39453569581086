import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../../../components/layout"
import { ProductListing } from "../../../components/product-listing"
import { Seo } from "../../../components/seo"
import slugify from "@sindresorhus/slugify"
import { MoreButton } from "../../../components/more-button"
import { title } from "../index.module.css"

export default function ProductTypeIndex({ data }) {
  console.log(data)
  return (
    <Layout>
      <div className="pb-[40px] pt-28 md:pt-0" />
      {data.allShopifyCollection.nodes.map((node) => {
        return (
          <div id={node.handle.replace(" ", "").toLowerCase()}>
            <h1 className="text-3xl text-center font-bold capitalize">{node.title}</h1>
            <ProductListing products={node.products} />
          </div>
        )
      })}
      {/* {products.pageInfo.hasNextPage && (
        <MoreButton to={`/search?p=${slugify(productType)}#more`}>
          More Products
        </MoreButton>
      )} */}
    </Layout>
  )
}

export const Head = ({ pageContext: { productType } }) => (
  <Seo title={`${productType}`} />
)

export const query = graphql`
    query MyQuery {
      allShopifyCollection(
        sort: {fields: products___variants___product___createdAt, order: ASC}

        ) {
        nodes {
          description
          handle
          title
          products {
            id
            tags
            title
            slug: gatsbyPath(
              filePath: "/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}"
            )
            images {
              id
              altText
              gatsbyImageData
            }
            priceRangeV2 {
              minVariantPrice {
                amount
                currencyCode
              }
            }
            vendor
          }
        }
      }
    }
`
