import clsx from "clsx"
import * as React from "react"
import { ProductCard } from "./product-card"
import { listingContainerStyle } from "./product-listing.module.css"

// To optimize LCP we mark the first product card as eager so the image gets loaded faster
export function ProductListing({ products = [] }) {
  console.log("$$" , products)
  return (
    <div className={clsx(products.length !== 1 && "grid grid-cols-2 md:grid-cols-4 flex-wrap child:mx-auto")}>
      {products.map((p, index) => (
        <div className={clsx( "col-span-full md:col-span-2  mx-auto  flex flex-row" )}>
        <ProductCard isSolo={products.length === 1} product={p} fullRow={index % 4 === 0} key={p.id} eager={index === 0} />
        </div>
      ))}
    </div>
  )
}
